import { fallbackChartColor, lineChartColors } from 'components/ChartsTheme'
import { IconType } from 'components/Icons'
import IndicesChart from 'components/IndiceChart'
import useApi, { QueryKey } from 'hooks/useApi'
import React, { ReactText, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import BigColorFigure from 'shared/components/BigColorFigure'
import Icon from 'shared/components/Icon'
import SelectInput from 'shared/components/SelectInput'
import SnapSlider, { SnapSliderProps } from 'shared/components/SnapSlider'
import Tooltip from 'shared/components/Tooltip'
import dateFormat from 'shared/helper/dateFormat'
import { numberFormat, percentFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { FundData, FundPerformanceData, FundQueryStringParameters, IndiceData, TimeRangeData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { setLocalStorage } from '../shared/helper/localStorage'

const IndicesComparison: React.FC = () => {
	const { getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.indicesComparison.pageTitle" />)

	const api = useApi()
	const queryClient = useQueryClient()

	const [fundQueryStringParameters, setFundQueryStringParameters] = useState(
		api.queries[QueryKey.fundPerformance]?.queryParameters as FundQueryStringParameters
	)

	const [data, setData] = useState<FundPerformanceData>()

	useLayoutEffect(() => {
		const getData = async () => {
			const queryResponse: FundPerformanceData = await queryClient.fetchQuery(
				api.getQuery(QueryKey.fundPerformance, fundQueryStringParameters as any, { area: undefined })
			)

			setData(queryResponse)
		}

		getData()
	}, [api, fundQueryStringParameters, queryClient])

	const [selectedIndiceLabel, setSelectedIndiceLabel] = useState<string>()

	const { data: indices }: { data?: IndiceData[] } = useQuery(api.getQuery(QueryKey.fundPerformanceIndices))

	const getIndiceLabel = (compareIndice: string) => {
		return (
			indices?.find((v, i) => {
				return v.value === compareIndice
			})?.description || ''
		)
	}

	useMemo(() => {
		setSelectedIndiceLabel(getIndiceLabel(fundQueryStringParameters.compareIndice || ''))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indices])

	const { data: timeRanges }: { data?: TimeRangeData[] } = useQuery(api.getQuery(QueryKey.fundPerformanceTimeRanges))

	const handleOnChange = async (queryParameters: FundQueryStringParameters) => {
		const updatedFundQueryStringParameters = { ...fundQueryStringParameters, ...queryParameters }

		if (queryParameters.compareIndice) {
			setSelectedIndiceLabel(getIndiceLabel(queryParameters.compareIndice))
		}

		setLocalStorage('fundQueryStringParameters', updatedFundQueryStringParameters)

		setFundQueryStringParameters(updatedFundQueryStringParameters)
	}

	const mappedTimeRanges = useMemo(
		() =>
			timeRanges?.map((v) => {
				return { value: v.value, label: t('view.indicesComparison.intervals.' + v.label) }
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[timeRanges]
	)

	return (
		<div className="indices-comparison center-view">
			<div className="indices-comparison__date text-align--center margin--vertical margin--small flex flex--justify-content-center">
				<span className="text--bold-spaced flex flex--align-items-center">
					{data && (
						<Trans
							i18nKey="generic.updatedAt"
							values={{
								date: dateFormat(data.updatedAt, {
									weekday: 'long',
									year: 'numeric',
									month: '2-digit',
									day: '2-digit',
								}),
							}}
						/>
					)}
					<Tooltip
						color="var(--color-cyan)"
						icon={IconType.info}
						headline={t('view.indicesComparison.tooltip.headline')}
						content={t('view.indicesComparison.tooltip.content')}
					/>
				</span>
			</div>
			<div className="indices-comparison__figures">
				{data &&
					data.fundValues.map((fund: FundData, index: number) => {
						return (
							<React.Fragment key={`fund-${index}`}>
								<BigColorFigure
									fill={lineChartColors[index]}
									value={
										fund.lastValueFund
											? percentFormat(fund.lastValueFund, {
												fixedFractionDigits: 2,
												addSignPrefix: true,
											})
											: t<string>('generic.notAvailable')
									}
								/>
								<div
									style={{ color: lineChartColors[index] || fallbackChartColor }}
									className="margin--horizontal no-margin--right font-size-s"
								>
									{fund.fundDescription}

									{fund.enddatum && (
										<>
											<br />
											<span>
												<Trans
													i18nKey="view.indicesComparison.until"
													values={{
														date: dateFormat(fund.enddatum, {
															month: '2-digit',
															day: '2-digit',
															year: 'numeric',
														}),
													}}
												/>
											</span>
										</>
									)}
								</div>
							</React.Fragment>
						)
					})}

				<div>
					{data && 0 !== data.fundValues.length && (
						<BigColorFigure
							value={percentFormat(data?.lastValueIndice, {
								fixedFractionDigits: 2,
								addSignPrefix: true,
							})}
						/>
					)}
					{data && 0 === data.fundValues.length && (
						<BigColorFigure
							value={`${numberFormat(data?.lastValueIndice, {
								fixedFractionDigits: 2,
							})} ${data.unit ?? ''}`}
						/>
					)}
					{data && 0 === data.fundValues.length && (
						<div className="margin--small margin--vertical text-align--right font-size-xs text-color-grey">
							<Trans
								i18nKey="view.indicesComparison.timeSinceLastUpdate"
								values={{
									date: dateFormat(data.updatedAt),
								}}
							/>
						</div>
					)}
				</div>

				<div>
					{data && 0 !== data.fundValues.length && (
						<span className="margin--horizontal no-margin--right font-size-s">
							<Trans i18nKey="view.indicesComparison.comparison" />:
						</span>
					)}
					{fundQueryStringParameters && indices && (
						<>
							<SelectInput
								onChange={(value: ReactText) => handleOnChange({ compareIndice: value as string })}
								value={fundQueryStringParameters.compareIndice}
								options={indices}
								renderModal={true}
								buttonColor="var(--color-white)"
							/>

							<div className="text-align--center font-size-s padding--horizontal padding--large">
								{selectedIndiceLabel}
							</div>
						</>
					)}
				</div>
			</div>

			{data && 0 === data.fundValues.length && (
				<div className="margin--vertical flex flex--align-items-start">
					<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
					<Trans i18nKey="view.indicesComparison.dummyDataDisclaimer" />
				</div>
			)}

			<div className="indices-comparison__graph">{data && <IndicesChart data={data} />}</div>

			{fundQueryStringParameters && mappedTimeRanges && mappedTimeRanges.length > 1 && (
				<SnapSlider
					className="indices-comparison__zoom"
					onChange={(value: SnapSliderProps['value']) =>
						handleOnChange({ history: value as FundQueryStringParameters['history'] })
					}
					value={fundQueryStringParameters.history}
					options={mappedTimeRanges}
				/>
			)}

			{data?.kursverlaufEreignisseErklaerung && (
				<div className="flex flex--align-items-start">
					<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
					<Trans i18nKey={`view.indicesComparison.eventsInfo.${data?.kursverlaufEreignisseErklaerung}`} />
				</div>
			)}
		</div>
	)
}

export default IndicesComparison
