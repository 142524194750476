import { IconType } from 'components/Icons'
import { activatedFeatures } from 'helper/activatedFeatures'
import { Trans } from 'react-i18next'
import { RiskOptions, UserGroup } from 'shared/enums'
import { RouteItem } from 'shared/hooks/useRouteHelper'
import AccountLocked from 'views/AccountLocked'
import BlocksAccount from 'views/BlocksAccount'
import Contact from 'views/Contact'
import Conversions from 'views/Conversions/Conversions'
import Salary from 'views/DeferredCompensation/Salary'
import SelectBonusAmount from 'views/DeferredCompensation/SelectBonusAmount'
import SelectBonusMonth from 'views/DeferredCompensation/SelectBonusMonth'
import Documents from 'views/Documents'
import Imprint from 'views/Imprint'
import ConfirmCaptcha from 'views/Login/ConfirmCaptcha'
import Login from 'views/Login/Login'
import SendMfa from 'views/Login/SendMfa'
import SendPassword from 'views/Login/SendPassword'
import SendUserId from 'views/Login/SendUserId'
import SetEmailAddress from 'views/Login/SetEmailAddress'
import SetPassword from 'views/Login/SetPassword'
import BenefitType from 'views/Payout/ChapterOne/BenefitType'
import ConfirmPayoutModel from 'views/Payout/ChapterOne/ConfirmPayoutModel'
import MixedPayout from 'views/Payout/ChapterOne/MixedPayout'
import MixedPayoutPreselection from 'views/Payout/ChapterOne/MixedPayoutSelection'
import PayoutDocuments from 'views/Payout/ChapterOne/PayoutDocuments/PayoutDocuments'
import PayoutEmployeeContribution from 'views/Payout/ChapterOne/PayoutEmployeeContribution'
import PayoutOverview from 'views/Payout/ChapterOne/PayoutOverview'
import PayoutReduction from 'views/Payout/ChapterOne/PayoutReduction'
import PensionDate from 'views/Payout/ChapterOne/PensionDate/PensionDate'
import PayoutModelInputForm from 'views/Payout/ChapterTwo/PayoutModelInputForm'
import PayoutConfirmation from 'views/Payout/PayoutConfirmation'
import PayoutDocumentsSuccess from 'views/Payout/PayoutDocumentsSuccess'
import PayoutFinalStep from 'views/Payout/PayoutFinalStep'
import PayoutInputSuccess from 'views/Payout/PayoutInputSuccess'
import PayoutOptions from 'views/Payout/PayoutOptions'
import PayoutSentSucess from 'views/Payout/PayoutSentSucess'
import PayoutSuccess from 'views/Payout/PayoutSuccess'
import PayoutSummary from 'views/Payout/PayoutSummary'
import PayoutModel from 'views/PayoutModel'
import ProfileOverview from 'views/Profile/ProfileOverview'
import RegistrationHelp from 'views/RegistrationHelp'
import RBConditions from 'views/RiskBenefit/RBConditions'
import RBOverview from 'views/RiskBenefit/RBOverview'
import RBPartnerConditions from 'views/RiskBenefit/RBPartnerConditions'
import RBPartnerForm from 'views/RiskBenefit/RBPartnerForm'
import RBPartnerSummary from 'views/RiskBenefit/RBPartnerSummary'
import RBSelectDuration from 'views/RiskBenefit/RBSelectDuration'
import RBSelection from 'views/RiskBenefit/RBSelection'
import RBSelectType from 'views/RiskBenefit/RBSelectType'
import RBSuccess from 'views/RiskBenefit/RBSuccess'
import RBSummary from 'views/RiskBenefit/RBSummary'
import RiskBenefit from 'views/RiskBenefit/RiskBenefit'
import TerminateDigitalParticipation from 'views/TerminateDigitalParticipation'
import AllYears from './views/Conversions/AllYears'
import SingleMonth from './views/Conversions/SingleMonth'
import SingleYear from './views/Conversions/SingleYear'
import Dashboard from './views/Dashboard'
import Bonus from './views/DeferredCompensation/Bonus'
import DeferredCompensation from './views/DeferredCompensation/DeferredCompensation'
import Selection from './views/DeferredCompensation/Selection'
import Success from './views/DeferredCompensation/Success'
import Summary from './views/DeferredCompensation/Summary'
import FAQ from './views/FAQ'
import FundComposition from './views/FundComposition'
import IndicesComparison from './views/IndicesComparison'
import Logout from './views/Logout'
import PasswordReset from './views/PasswordReset'
import PensionAssets from './views/PensionAssets'
import Welcome from './views/Welcome'

import Migration from 'views/Login/Migration/Migration'
import StartMigration from 'views/Login/Migration/StartMigration'
import Email from 'views/Login/Migration/Email'
import EnterCode from 'views/Login/Migration/EnterCode'
import Phone from 'views/Login/Migration/Phone'
import Password from 'views/Login/Migration/Password'
import MigrationSuccess from 'views/Login/Migration/Success'
import SendPasswordV2 from 'views/Login/V2auth/SendPasswordV2'

import ChangeEmail from 'views/Profile/ChangeEmail'
import Profile from 'views/Profile/Profile'
import ChangeEmailSuccess from 'views/Profile/ChangeEmailSuccess'
import ChangePhoneNumber from 'views/Profile/ChangePhoneNumber'
import RegisterV2 from 'views/Login/V2auth/RegisterV2'
import ChangePassword from 'views/Profile/ChangePassword'
import ChangePasswordSuccess from 'views/Profile/ChangePasswordSuccess'
import ChangePhoneNumberSuccess from 'views/Profile/ChangePhoneNumberSuccess'
import ForgotPasswordV2 from 'views/Login/V2auth/ForgotPasswordV2'

const routesDictionary: { [key: string]: RouteItem } = {
	dashboard: {
		path: '/',
		view: <Dashboard />,
		navigationName: <Trans i18nKey="view.dashboard.navigationName" />,
		showInNavigation: true,
		icon: IconType.menuDashboard,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.WeaklyAuthenticated],
	},
	migration: {
		path: '/migration',
		authRequired: true,
		navigationName: <Trans i18nKey="view.migration.navigationName" />,
		showInNavigation: true,
		view: <Migration />,
		icon: IconType.menuLogout,
		blockStatus: [UserGroup.MigratedToNewPool],
		children: {
			startMigration: {
				path: '/start',
				view: <StartMigration />,
			},
			newEmail: {
				path: '/enter-email',
				view: <Email />,
			},
			phone: {
				path: '/phone',
				view: <Phone />,
			},
			password: {
				path: '/password',
				view: <Password />,
			},

			enterTextMessage: {
				path: '/text-message-code',
				view: <EnterCode type={'phone_number'} action={'confirm_attribute'} />,
			},
			tanForEmail: {
				path: '/email-code',
				view: <EnterCode type={'email'} action={'confirm_attribute'} />,
			},
			success: {
				path: '/success',
				view: <MigrationSuccess />,
			},
		},
	},

	deferredCompensation: {
		path: '/deferred-compensation',
		view: <DeferredCompensation />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.deferredCompensation.navigationName" />,
		icon: IconType.menuDeferredCompensation,
		blockStatus: [
			UserGroup.LeftWithEntitlement,
			UserGroup.Vorstand,
			UserGroup.NotMigratedToNewPool,
			UserGroup.Leistungsfall,
			UserGroup.WeaklyAuthenticated,
			UserGroup.UnverfallbarAusgeschieden,
		],

		children: {
			typeSelection: {
				path: '/selection',
				view: <Selection />,
			},
			salary: {
				path: '/salary',
				view: <Salary />,
			},
			selectBonusMonth: {
				path: '/select-bonus-month',
				view: <SelectBonusMonth id={''} />,
				parameters: ['id'],
			},
			selectBonusAmount: {
				path: '/select-bonus-amount',
				view: <SelectBonusAmount id={''} month={0} />,
				parameters: ['id', 'month'],
			},
			bonus: {
				path: '/bonus',
				view: <Bonus id={''} month={0} />,
				parameters: ['id', 'month'],
			},
			summary: {
				path: '/summary',
				view: <Summary />,
			},
			success: {
				path: '/success',
				view: <Success />,
			},
		},
	},
	conversions: {
		path: '/conversions',
		view: <Conversions />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.conversions.navigationName" />,
		icon: IconType.menuConversions,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.Leistungsfall, UserGroup.WeaklyAuthenticated],

		children: {
			allYears: {
				path: '/overview',
				view: <AllYears />,
			},
			singleYear: {
				path: '',
				view: <SingleYear year={0} />,
				parameters: ['year'],
			},
			singleMonth: {
				path: '',
				view: <SingleMonth year={0} month={0} />,
				parameters: ['year', 'month'],
			},
		},
	},
	payoutOptions: {
		path: '/payout-options',
		view: activatedFeatures.payoutOptions ? <PayoutOptions /> : <></>,
		showInNavigation: activatedFeatures.payoutOptions ? true : false,
		navigationName: <Trans i18nKey="view.payoutOptions.navigationName" />,
		icon: IconType.menuPayout,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.WeaklyAuthenticated],
		children: {
			confirmDetails: {
				path: '/confirm-details',
				view: <ConfirmPayoutModel payoutModel={undefined} />,
				parameters: ['payoutModel'],
			},
			overview: {
				path: '/overview',
				view: <PayoutOverview />,
			},
			benefitType: {
				path: '/benefit-type',
				view: <BenefitType />,
			},
			payoutReduction: {
				path: '/payout-reduction',
				view: <PayoutReduction />,
			},
			PayoutEmployeeContribution: {
				path: '/payout-employer-contribution',
				view: <PayoutEmployeeContribution reduction={undefined} />,
				parameters: ['reduction'],
			},
			pensionDate: {
				path: '/payout-date',
				view: <PensionDate pensionType={undefined} />,
				parameters: ['pensionType'],
			},
			payoutModel: {
				path: '/payout-model',
				view: <PayoutModel />,
			},
			payoutSummary: {
				path: '/payout-summary',
				view: <PayoutSummary />,
			},
			mixedPayoutPreslection: {
				path: '/mixed-payout-preselection',
				view: <MixedPayoutPreselection />,
			},
			mixedPayout: {
				path: '/mixed-payout',
				view: <MixedPayout preSelection={undefined} />,
				parameters: ['preSelection'],
			},
			chapterOneSummary: {
				path: '/chapter-summary',
				view: <BenefitType />,
				parameters: ['preSelection'],
			},
			payoutModelInputForm: {
				path: '/payoutmodel-input-form',
				view: <PayoutModelInputForm step={0} />,
				parameters: ['step'],
			},
			payoutSuccess: {
				path: '/success',
				view: <PayoutSuccess />,
			},
			payoutInputSuccess: {
				path: '/payout-input-success',
				view: <PayoutInputSuccess />,
			},
			payoutDocuments: {
				path: '/documents',
				view: <PayoutDocuments />,
			},
			payoutConfirmation: {
				path: '/payout-confirmation',
				view: <PayoutConfirmation />,
			},
			payoutDocumentsSuccess: {
				path: '/payout-documents-success',
				view: <PayoutDocumentsSuccess />,
			},
			payoutFinalStep: {
				path: '/finalize',
				view: <PayoutFinalStep />,
			},
			payoutSentSucess: {
				path: '/payout-sent-success',
				view: <PayoutSentSucess />,
			},
		},
	},
	blocksAccount: {
		path: '/blocks-account',
		view: <BlocksAccount />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.accountBlocks.navigationName" />,
		icon: IconType.menuBlocksAccount,
		blockStatus: [
			UserGroup.Vo19,
			UserGroup.NotMigratedToNewPool,
			UserGroup.Leistungsfall,
			UserGroup.WeaklyAuthenticated,
		],

		allowStatus: [UserGroup.Vo20],
	},
	riskBenefit: {
		path: '/risk-benefit',
		view: <RiskBenefit />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.riskBenefit.navigationName" />,
		icon: IconType.menuRiskBenefit,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.Leistungsfall, UserGroup.WeaklyAuthenticated],

		children: {
			RBSelection: {
				path: '/selection',
				view: <RBSelection />,
			},
			RBDisabilityBenefit: {
				path: '/disability-benefit',
				view: <RBOverview riskId={RiskOptions.disabilityBenefit} />,
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBDisabilityBenefitChange: {
				path: '/disability-benefit/change',
				view: <RBSelectType riskId={RiskOptions.disabilityBenefit} />,
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBDisabilityBenefitChangeSelect: {
				path: '/disability-benefit/change',
				view: <RBSelectDuration riskId={RiskOptions.disabilityBenefit} type={undefined} />,
				parameters: ['type'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBDisabilityBenefitConditions: {
				path: '/disability-benefit/conditions',
				view: <RBConditions riskId={RiskOptions.disabilityBenefit} type={undefined} />,
				parameters: ['type'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBDisabilityBenefitConditionsWithDuration: {
				path: '/disability-benefit/conditions',
				view: <RBConditions riskId={RiskOptions.disabilityBenefit} type={undefined} duration={undefined} />,
				parameters: ['type', 'duration'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBDisabilityBenefitSummary: {
				path: '/disability-benefit/summary',
				view: <RBSummary riskId={RiskOptions.disabilityBenefit} type={undefined} />,
				parameters: ['type'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBDisabilityBenefitSummaryWithDuration: {
				path: '/disability-benefit/summary',
				view: <RBSummary riskId={RiskOptions.disabilityBenefit} type={undefined} duration={undefined} />,
				parameters: ['type', 'duration'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerBenefit: {
				path: '/partner-benefit',
				view: <RBOverview riskId={RiskOptions.partnerBenefit} />,
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerBenefitChange: {
				path: '/partner-benefit/change',
				view: <RBSelectType riskId={RiskOptions.partnerBenefit} />,
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerBenefitChangeSelect: {
				path: '/partner-benefit/change',
				view: <RBSelectDuration riskId={RiskOptions.partnerBenefit} type={undefined} />,
				parameters: ['type'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerBenefitConditions: {
				path: '/partner-benefit/conditions',
				view: <RBConditions riskId={RiskOptions.partnerBenefit} type={undefined} />,
				parameters: ['type'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerBenefitConditionsWithDuration: {
				path: '/partner-benefit/conditions',
				view: <RBConditions riskId={RiskOptions.partnerBenefit} type={undefined} duration={undefined} />,
				parameters: ['type', 'duration'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerBenefitSummary: {
				path: '/partner-benefit/summary',
				view: <RBSummary riskId={RiskOptions.partnerBenefit} type={undefined} />,
				parameters: ['type'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerBenefitSummaryWithDuration: {
				path: '/partner-benefit/summary',
				view: <RBSummary riskId={RiskOptions.partnerBenefit} type={undefined} duration={undefined} />,
				parameters: ['type', 'duration'],
				blockStatus: [UserGroup.LeftWithEntitlement, UserGroup.UnverfallbarAusgeschieden],
			},
			RBPartnerForm: {
				path: '/set-partner',
				view: <RBPartnerForm />,
			},
			RBPartnerConditions: {
				path: '/set-partner/conditions',
				view: <RBPartnerConditions />,
			},
			RBPartnerRemove: {
				path: '/remove-partner',
				view: <RBPartnerSummary state="removePartner" />,
			},
			RBPartnerSummary: {
				path: '/set-partner/summary',
				view: <RBPartnerSummary state="default" />,
			},
			RBSuccess: {
				path: '/success',
				view: <RBSuccess state="default" />,
			},
			RBSuccessFastLane: {
				path: '/success-fl',
				view: <RBSuccess state="fastlane" />,
			},
		},
	},
	pensionAssets: {
		path: '/pension-assets',
		view: <PensionAssets />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.pensionAssets.navigationName" />,
		/**
		 * Feature Flag für PROD
		 */
		blockStatus: activatedFeatures.leistungsfallVersorgungsguthabenPermission ? [UserGroup.NotMigratedToNewPool, UserGroup.WeaklyAuthenticated] : [UserGroup.NotMigratedToNewPool, UserGroup.Leistungsfall, UserGroup.WeaklyAuthenticated], 

		icon: IconType.menuPension,
	},
	indicesComparison: {
		path: '/indices-comparison',
		view: <IndicesComparison />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.indicesComparison.navigationName" />,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.Leistungsfall, UserGroup.WeaklyAuthenticated],
		icon: IconType.menuChart,
	},
	fundComposition: {
		path: '/fund-composition',
		view: <FundComposition />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.fundComposition.navigationName" />,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.Leistungsfall],
		icon: IconType.menuFundComposition,
	},

	documents: {
		path: '/documents',
		view: <Documents />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.documents.navigationName" />,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.WeaklyAuthenticated],
		icon: IconType.menuDocuments,
	},
	faq: {
		path: '/faq',
		view: <FAQ />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.faq.navigationName" />,
		hideIfLoggedIn: false,
		hideIfLoggedOut: true,
		authRequired: false,
		blockStatus: [UserGroup.NotMigratedToNewPool],
		icon: IconType.menuInfo,
	},
	contact: {
		path: '/contact',
		view: <Contact />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.contact.navigationName" />,
		hideIfLoggedIn: false,

		icon: IconType.menuCall,
	},
	profile: {
		path: '/profile',
		view: <Profile />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.profile.navigationName" />,
		hideIfLoggedIn: false,
		hideIfLoggedOut: false,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.WeaklyAuthenticated],
		icon: IconType.menuProfile,
		children: {
			profileOverview: {
				path: '/overview',
				view: <ProfileOverview />,
			},
			changeEmail: {
				path: '/change-email',
				view: <ChangeEmail />,
			},
			changePassword: {
				path: '/change-password',
				view: <ChangePassword />,
			},
			changePhone: {
				path: '/change-phone',
				view: <ChangePhoneNumber />,
			},
			changeEmailSuccess: {
				path: '/change-email-sucess',
				view: <ChangeEmailSuccess />,
			},
			changePasswordSuccess: {
				path: '/change-password-sucess',
				view: <ChangePasswordSuccess />,
			},
			changePhoneSuccess: {
				path: '/change-phone-sucess',
				view: <ChangePhoneNumberSuccess />,
			},
		},
	},

	welcome: {
		path: '/welcome',
		view: <Welcome />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.welcome.navigationName" />,
		hideIfLoggedIn: true,
		hideIfLoggedOut: false,
		authRequired: false,
		blockStatus: [UserGroup.NotMigratedToNewPool, UserGroup.WeaklyAuthenticated],
		icon: IconType.menuWelcome,
	},
	register: {
		path: '/register',
		view: <RegisterV2 skip={undefined} initialPassword={undefined} />,
		// parameters: ['skip'],
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.register.navigationName" />,

		hideIfLoggedOut: false,
		blockStatus: [UserGroup.StronglyAuthenticated],
		authRequired: false,
		icon: IconType.menuRegister,
	},
	login: {
		path: '/login',
		view: <Login />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.login.navigationName" />,
		hideIfLoggedIn: true,
		hideIfLoggedOut: false,
		authRequired: false,
		icon: IconType.menuLogin,
		children: {
			sendUserId: {
				path: '/user-id',
				view: <SendUserId />,
			},
			sendPassword: {
				path: '/password',
				view: <SendPassword />,
			},
			passwordLogin: {
				path: '/password-login',
				view: <SendPasswordV2 />,
			},

			setEmailAddress: {
				path: '/set-email-address',
				view: <SetEmailAddress />,
			},
			setPassword: {
				path: '/set-password',
				view: <SetPassword />,
			},
			sendCaptcha: {
				path: '/captcha',
				view: <ConfirmCaptcha />,
			},
			sendMfa: {
				path: '/mfa',
				view: <SendMfa />,
			},
			enterCode: {
				path: '/enter-code',
				view: <EnterCode type={'phone_number'} action={'respond_to_challenge'} />,
			},
		},
	},
	registrationHelp: {
		path: '/registration-help',
		view: <RegistrationHelp />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.registrationHelp.navigationName" />,
		hideIfLoggedIn: true,
		authRequired: false,
		icon: IconType.menuHelp,
	},
	passwordReset: {
		path: '/password-reset',
		view: <PasswordReset />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.passwordReset.navigationName" />,
		hideIfLoggedIn: true,
		authRequired: false,
		icon: IconType.menuPasswordReset,
	},
	passwordResetV2: {
		path: '/password-reset-v2',
		view: <ForgotPasswordV2 />,

		showInNavigation: false,
		navigationName: <Trans i18nKey="view.passwordReset.navigationName" />,
		hideIfLoggedIn: true,
		authRequired: false,
		icon: IconType.menuPasswordReset,
	},
	logout: {
		path: '/logout',
		view: <Logout />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.logout.navigationName" />,
		hideIfLoggedIn: false,
		hideIfLoggedOut: true,
		authRequired: false,
		icon: IconType.menuLogout,
	},

	accountLocked: {
		path: '/account-locked',
		view: <AccountLocked />,
		authRequired: false,
		hideIfLoggedIn: true,
	},
	terminateDigitalParticipation: {
		path: '/terminate-digital-participation',
		view: <TerminateDigitalParticipation />,
		authRequired: false,
		hideIfLoggedIn: true,
	},

	imprint: {
		path: '/imprint',
		view: <Imprint />,
		showInNavigation: true,
		authRequired: false,
		navigationName: <Trans i18nKey="view.imprint.navigationName" />,
		hideIfLoggedIn: false,
	},
}

export default routesDictionary
